<mat-dialog-content>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <label class="page-title">
              {{ 'License and Use Terms' | translate }}
            </label>
          </div>
          <div class="card-body">
            <div class="text-content p-r-20">
              <div>
                <p class="c1">
                  <span
                    style="
                      overflow: hidden;
                      display: inline-block;
                      margin: 0px 0px;
                      border: 0px solid #000000;
                      transform: rotate(0rad) translateZ(0px);
                      -webkit-transform: rotate(0rad) translateZ(0px);
                      width: 74px;
                      height: 44px;
                    "
                    ><img
                      alt=""
                      src="/assets/images/terms/image2.png"
                      style="
                        width: 74px;
                        height: 44px;
                        margin-left: -0px;
                        margin-top: -0px;
                        transform: rotate(0rad) translateZ(0px);
                        -webkit-transform: rotate(0rad) translateZ(0px);
                      "
                      title=""
                  /></span>
                </p>
                <p class="c1">
                  <span class="c6 c12">BYD ENERGY DO BRASIL LTDA</span>
                </p>
                <p class="c1">
                  <span class="c4"
                    >Av. Antonio Buscato, 230, Terminal Intermodal de
                    Cargas</span
                  >
                </p>
                <p class="c1">
                  <span class="c4">Campinas/SP - CEP: 13069-119</span>
                </p>
                <p class="c1"><span class="c6 c11">www.byd.com.br</span></p>
                <p class="c1 c8"><span class="c0"></span></p>
                <p class="c1">
                  <span
                    style="
                      overflow: hidden;
                      display: inline-block;
                      margin: 0px 0px;
                      border: 0px solid #000000;
                      transform: rotate(0rad) translateZ(0px);
                      -webkit-transform: rotate(0rad) translateZ(0px);
                      width: 649.73px;
                      height: 2px;
                    "
                    ><img
                      alt=""
                      src="assets/images/terms/image3.png"
                      style="
                        width: 649.73px;
                        height: 2px;
                        margin-left: 0px;
                        margin-top: 0px;
                        transform: rotate(0rad) translateZ(0px);
                        -webkit-transform: rotate(0rad) translateZ(0px);
                      "
                      title=""
                  /></span>
                </p>
              </div>
              <p class="c9">
                <span class="c6"
                  >Termos e condi&ccedil;&otilde;es gerais de uso da plataforma
                  BYD e de compra e venda</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >Os servi&ccedil;os s&atilde;o fornecidos pela pessoa
                  jur&iacute;dica com a seguinte Raz&atilde;o Social: BYD ENERGY
                  DO BRASIL, inscrito no CNPJ sob o n&ordm; 21.858.948/0001-52 e
                  Raz&atilde;o Social: BYD DO BRASIL, inscrito no CNPJ sob o
                  n&ordm; 17.140.820/0008-58, titular da propriedade intelectual
                  sobre conte&uacute;dos e demais ativos relacionados &agrave;
                  plataforma da BYD.</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5"><span class="c0">1. Do objeto</span></p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >A plataforma visa licenciar o uso ao software, website,
                  aplicativos e demais ativos de propriedade intelectual,
                  fornecendo ferramentas para auxiliar e dinamizar o dia a dia
                  dos seus usu&aacute;rios.</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >A plataforma caracteriza-se pela presta&ccedil;&atilde;o do
                  seguinte servi&ccedil;o: Compra e venda</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >A plataforma realiza a venda &agrave; dist&acirc;ncia por
                  meio eletr&ocirc;nico dos seguintes produtos ou
                  servi&ccedil;os: de pain&eacute;is, inversores, baterias,
                  cabos, conectores, string box, m&oacute;dulo ou at&eacute;
                  mesmo o KIT completo desses produtos (&lsquo;Kit
                  Fotovoltaico&rdquo;).
                </span>
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0">2. Da aceita&ccedil;&atilde;o</span>
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >O presente Termo estabelece obriga&ccedil;&otilde;es
                  contratadas de livre e espont&acirc;nea vontade, por tempo
                  indeterminado, entre a plataforma e as pessoas f&iacute;sicas
                  ou jur&iacute;dicas, usu&aacute;rias do site.</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >Ao utilizar a plataforma o usu&aacute;rio aceita
                  integralmente as presentes normas e compromete-se a
                  observ&aacute;-las, sob o risco de aplica&ccedil;&atilde;o das
                  penalidades cab&iacute;veis.</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >A aceita&ccedil;&atilde;o do presente instrumento &eacute;
                  imprescind&iacute;vel para o acesso e para a
                  utiliza&ccedil;&atilde;o de quaisquer servi&ccedil;os
                  fornecidos pela empresa. Caso n&atilde;o concorde com as
                  disposi&ccedil;&otilde;es deste instrumento, o usu&aacute;rio
                  n&atilde;o deve utiliz&aacute;-los.</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0">3. Do acesso dos usu&aacute;rios</span>
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >Ser&atilde;o utilizadas todas as solu&ccedil;&otilde;es
                  t&eacute;cnicas &agrave; disposi&ccedil;&atilde;o do
                  respons&aacute;vel pela plataforma para permitir o acesso ao
                  servi&ccedil;o 24 (vinte e quatro) horas por dia, 7 (sete)
                  dias por semana. No entanto, a navega&ccedil;&atilde;o na
                  plataforma ou em alguma de suas p&aacute;ginas poder&aacute;
                  ser interrompida, limitada ou suspensa para
                  atualiza&ccedil;&otilde;es, modifica&ccedil;&otilde;es ou
                  qualquer a&ccedil;&atilde;o necess&aacute;ria ao seu bom
                  funcionamento.</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5"><span class="c0">4. Do cadastro</span></p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >O acesso &agrave;s funcionalidades da plataforma
                  exigir&aacute; a realiza&ccedil;&atilde;o de um cadastro
                  pr&eacute;vio e, a depender dos servi&ccedil;os ou produtos
                  escolhidos, o pagamento de determinado valor.</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >Ao se cadastrar o usu&aacute;rio dever&aacute; informar dados
                  completos, recentes e v&aacute;lidos, sendo de sua exclusiva
                  responsabilidade manter referidos dados atualizados, bem como
                  o usu&aacute;rio se compromete com a veracidade dos dados
                  fornecidos.</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >O usu&aacute;rio se compromete a n&atilde;o informar seus
                  dados cadastrais e/ou de acesso &agrave; plataforma a
                  terceiros, responsabilizando-se integralmente pelo uso que
                  deles seja feito.</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >Menores de 18 anos e aqueles que n&atilde;o possu&iacute;rem
                  plena capacidade civil dever&atilde;o obter previamente o
                  consentimento expresso de seus respons&aacute;veis legais para
                  utiliza&ccedil;&atilde;o da plataforma e dos servi&ccedil;os
                  ou produtos, sendo de responsabilidade exclusiva dos mesmos o
                  eventual acesso por menores de idade e por aqueles que
                  n&atilde;o possuem plena capacidade civil sem a pr&eacute;via
                  autoriza&ccedil;&atilde;o.</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >Mediante a realiza&ccedil;&atilde;o do cadastro o
                  usu&aacute;rio declara e garante expressamente ser plenamente
                  capaz, podendo exercer e usufruir livremente dos
                  servi&ccedil;os e produtos.</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >O usu&aacute;rio dever&aacute; fornecer um endere&ccedil;o de
                  e-mail v&aacute;lido, atrav&eacute;s do qual o site
                  realizar&aacute; todas comunica&ccedil;&otilde;es
                  necess&aacute;rias.</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >Ap&oacute;s a confirma&ccedil;&atilde;o do cadastro, o
                  usu&aacute;rio possuir&aacute; um login e uma senha pessoal, a
                  qual assegura ao usu&aacute;rio o acesso individual &agrave;
                  mesma. Desta forma, compete ao usu&aacute;rio exclusivamente a
                  manuten&ccedil;&atilde;o de referida senha de maneira
                  confidencial e segura, evitando o acesso indevido &agrave;s
                  informa&ccedil;&otilde;es pessoais.</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >Toda e qualquer atividade realizada com o uso da senha
                  ser&aacute; de responsabilidade do usu&aacute;rio, que
                  dever&aacute; informar prontamente a plataforma em caso de uso
                  indevido da respectiva senha.</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >N&atilde;o ser&aacute; permitido ceder, vender, alugar ou
                  transferir, de qualquer forma, a conta, que &eacute; pessoal e
                  intransfer&iacute;vel.</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >Caber&aacute; ao usu&aacute;rio assegurar que o seu
                  equipamento seja compat&iacute;vel com as
                  caracter&iacute;sticas t&eacute;cnicas que viabilize a
                  utiliza&ccedil;&atilde;o da plataforma e dos servi&ccedil;os
                  ou produtos.</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c10"
                  >O usu&aacute;rio poder&aacute;, a qualquer tempo, requerer o
                  cancelamento de seu cadastro junto ao site. O seu </span
                ><span class="c10 c13">descadastramento</span
                ><span class="c0"
                  >&nbsp;ser&aacute; realizado o mais rapidamente
                  poss&iacute;vel, desde que n&atilde;o sejam verificados
                  d&eacute;bitos em aberto.</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >O usu&aacute;rio, ao aceitar os Termos e Pol&iacute;tica de
                  Privacidade, autoriza expressamente a plataforma a coletar,
                  usar, armazenar, tratar, ceder ou utilizar as
                  informa&ccedil;&otilde;es derivadas do uso dos
                  servi&ccedil;os, do site e quaisquer plataformas, incluindo
                  todas as informa&ccedil;&otilde;es preenchidas pelo
                  usu&aacute;rio no momento em que realizar ou atualizar seu
                  cadastro, al&eacute;m de outras expressamente descritas na
                  Pol&iacute;tica de Privacidade que dever&aacute; ser
                  autorizada pelo usu&aacute;rio.</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0">5. Dos servi&ccedil;os ou produtos</span>
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >A plataforma poder&aacute; disponibilizar para o
                  usu&aacute;rio um conjunto espec&iacute;fico de
                  funcionalidades e ferramentas para otimizar o uso dos
                  servi&ccedil;os e produtos.</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >Na plataforma os servi&ccedil;os ou produtos oferecidos
                  est&atilde;o descritos e apresentados com o maior grau de
                  exatid&atilde;o, contendo informa&ccedil;&otilde;es sobre suas
                  caracter&iacute;sticas, qualidades, quantidades,
                  composi&ccedil;&atilde;o, pre&ccedil;o, garantia, prazos de
                  validade e origem, entre outros dados, bem como sobre os
                  riscos que apresentam &agrave; sa&uacute;de e seguran&ccedil;a
                  do usu&aacute;rio.</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >Antes de finalizar a compra sobre determinado produto ou
                  servi&ccedil;o, o usu&aacute;rio dever&aacute; se informar
                  sobre as suas especifica&ccedil;&otilde;es e sobre a sua
                  destina&ccedil;&atilde;o.</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >A entrega de servi&ccedil;os ou produtos adquiridos na
                  plataforma ser&aacute; informada no momento da
                  finaliza&ccedil;&atilde;o da compra.</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5"><span class="c0">6. Dos pre&ccedil;os</span></p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >A plataforma se reserva no direito de reajustar
                  unilateralmente, a qualquer tempo, os valores dos
                  servi&ccedil;os ou produtos sem consulta ou anu&ecirc;ncia
                  pr&eacute;via do usu&aacute;rio.</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >Os valores aplicados s&atilde;o aqueles que est&atilde;o em
                  vigor no momento do pedido.</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >Os pre&ccedil;os s&atilde;o indicados em reais e n&atilde;o
                  incluem as taxas de entrega, as quais s&atilde;o especificadas
                  &agrave; parte e s&atilde;o informadas ao usu&aacute;rio antes
                  da finaliza&ccedil;&atilde;o do pedido.</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >Na contrata&ccedil;&atilde;o de determinado servi&ccedil;o ou
                  produto, a plataforma poder&aacute; solicitar as
                  informa&ccedil;&otilde;es financeiras do usu&aacute;rio, como
                  CPF, endere&ccedil;o de cobran&ccedil;a e dados de
                  cart&otilde;es. Ao inserir referidos dados o usu&aacute;rio
                  concorda que sejam cobrados, de acordo com a forma de
                  pagamento que venha a ser escolhida, os pre&ccedil;os
                  ent&atilde;o vigentes e informados quando da
                  contrata&ccedil;&atilde;o. Referidos dados financeiros
                  poder&atilde;o ser armazenadas para facilitar acessos e
                  contrata&ccedil;&otilde;es futuras.</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >A contrata&ccedil;&atilde;o dos servi&ccedil;os poder&aacute;
                  ser renovada automaticamente pela plataforma,
                  independentemente de comunica&ccedil;&atilde;o ao
                  usu&aacute;rio, mediante cobran&ccedil;a peri&oacute;dica da
                  mesma forma de pagamento indicada pelo usu&aacute;rio quando
                  da contrata&ccedil;&atilde;o do servi&ccedil;o.</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5"><span class="c0">7. Do cancelamento</span></p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >O usu&aacute;rio poder&aacute; cancelar a
                  contrata&ccedil;&atilde;o dos servi&ccedil;os de acordo com os
                  termos que forem definidos no momento de sua
                  contrata&ccedil;&atilde;o. Ainda, o usu&aacute;rio
                  tamb&eacute;m poder&aacute; cancelar os servi&ccedil;os em
                  at&eacute; 7 (sete) dias ap&oacute;s a
                  contrata&ccedil;&atilde;o, mediante contato com o business
                  center da BYD, de acordo com o C&oacute;digo de Defesa do
                  Consumidor (Lei no. 8.078/90).</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >O servi&ccedil;o poder&aacute; ser cancelado por:</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >a) parte do usu&aacute;rio: nessas condi&ccedil;&otilde;es os
                  servi&ccedil;os somente cessar&atilde;o quando
                  conclu&iacute;do o ciclo vigente ao tempo do
                  cancelamento;</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >b) viola&ccedil;&atilde;o dos Termos de Uso: os
                  servi&ccedil;os ser&atilde;o cessados imediatamente.</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0">8. Da troca e devolu&ccedil;&atilde;o</span>
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >A pol&iacute;tica de troca e devolu&ccedil;&otilde;es da
                  plataforma &eacute; regida conforme o C&oacute;digo de Defesa
                  do Consumidor (Lei n&ordm; 8.078/90).</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >A troca e/ou devolu&ccedil;&atilde;o do produto poder&aacute;
                  ocorrer por:</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0">a) direito de arrependimento;</span>
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0">b) v&iacute;cio do produto.</span>
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >Em caso de arrependimento, o usu&aacute;rio poder&aacute;
                  devolver o produto em at&eacute; 7 (sete) dias ap&oacute;s o
                  seu recebimento, mediante contato com o business center da BYD
                  de acordo com o C&oacute;digo de Defesa do Consumidor (Lei
                  n&ordm; 8.078/90).</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >Em caso de v&iacute;cio do produto, dever&aacute; ser
                  verificado v&iacute;cios de qualidade ou quantidade que tornem
                  o produto impr&oacute;prios ou inadequados ao consumo a que se
                  destinam ou que lhes diminuam o valor. Ainda, poder&atilde;o
                  ser trocados ou devolvidos os produtos ou servi&ccedil;os que
                  apresentam disparidade com as indica&ccedil;&otilde;es
                  constantes na plataforma no momento da compra ou na embalagem,
                  respeitando as varia&ccedil;&otilde;es decorrentes de sua
                  natureza.</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >O usu&aacute;rio dever&aacute; entrar em contato com o SAC da
                  BYD t&atilde;o logo constate o v&iacute;cio. Se, no prazo
                  m&aacute;ximo de 30 (trinta) dias, n&atilde;o for
                  poss&iacute;vel resolver o v&iacute;cio ou, independentemente
                  deste prazo, a substitui&ccedil;&atilde;o das partes viciadas
                  puder comprometer a qualidade ou caracter&iacute;sticas do
                  produto ou servi&ccedil;o, diminuir-lhe o valor ou se tratar
                  de produto ou servi&ccedil;o essencial, o usu&aacute;rio
                  poder&aacute; optar pela substitui&ccedil;&atilde;o do produto
                  por outro da mesma esp&eacute;cie ou pela
                  reexecu&ccedil;&atilde;o do servi&ccedil;o, pela
                  devolu&ccedil;&atilde;o das quantias pagas ou pelo abatimento
                  proporcional do pre&ccedil;o.</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5"><span class="c0">9. Do suporte</span></p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >Em caso de qualquer d&uacute;vida, sugest&atilde;o ou
                  problema com a utiliza&ccedil;&atilde;o da plataforma, o
                  usu&aacute;rio poder&aacute; entrar em contato com o suporte,
                  atrav&eacute;s do email bp.energy&#64;byd.com.</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >Estes servi&ccedil;os de atendimento ao usu&aacute;rio
                  estar&atilde;o dispon&iacute;veis nos seguintes dias e
                  hor&aacute;rios: Segunda a quinta-feira, das 8h &agrave;s 18h
                  e sexta-feira, das 8h &agrave;s 17h.</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0">10. Das responsabilidades</span>
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >&Eacute; de responsabilidade do usu&aacute;rio:</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >a) defeitos ou v&iacute;cios t&eacute;cnicos originados no
                  pr&oacute;prio sistema do usu&aacute;rio;</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >b) a correta utiliza&ccedil;&atilde;o da plataforma, dos
                  servi&ccedil;os ou produtos oferecidos, prezando pela boa
                  conviv&ecirc;ncia, pelo respeito e cordialidade entre os
                  usu&aacute;rios;</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >c) pelo cumprimento e respeito ao conjunto de regras disposto
                  nesse Termo de Condi&ccedil;&otilde;es Geral de Uso, na
                  respectiva Pol&iacute;tica de Privacidade e na
                  legisla&ccedil;&atilde;o nacional e internacional;</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >d) pela prote&ccedil;&atilde;o aos dados de acesso &agrave;
                  sua conta/perfil (login e senha).</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >&Eacute; de responsabilidade da plataforma da BYD:</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >a) indicar as caracter&iacute;sticas do servi&ccedil;o ou
                  produto;</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >b) os defeitos e v&iacute;cios encontrados no servi&ccedil;o
                  ou produto oferecido desde que lhe tenha dado causa;</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >c) as informa&ccedil;&otilde;es que foram por ele divulgadas,
                  sendo que os coment&aacute;rios ou informa&ccedil;&otilde;es
                  divulgadas por usu&aacute;rios s&atilde;o de inteira
                  responsabilidade dos pr&oacute;prios usu&aacute;rios;</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >d) os conte&uacute;dos ou atividades il&iacute;citas
                  praticadas atrav&eacute;s da sua plataforma.</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >A plataforma n&atilde;o se responsabiliza por links externos
                  contidos em seu sistema que possam redirecionar o
                  usu&aacute;rio &agrave; ambiente externo a sua rede.</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >N&atilde;o poder&atilde;o ser inclu&iacute;dos links externos
                  ou p&aacute;ginas que sirvam para fins comerciais ou
                  publicit&aacute;rios ou quaisquer informa&ccedil;&otilde;es
                  il&iacute;citas, violentas, pol&ecirc;micas,
                  pornogr&aacute;ficas, xenof&oacute;bicas,
                  discriminat&oacute;rias ou ofensivas.</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0">11. Dos direitos autorais</span>
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >O presente Termo de Uso concede aos usu&aacute;rios uma
                  licen&ccedil;a n&atilde;o exclusiva, n&atilde;o
                  transfer&iacute;vel e n&atilde;o sublicenci&aacute;vel, para
                  acessar e fazer uso da plataforma e dos servi&ccedil;os e
                  produtos por ela disponibilizados.</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >A estrutura do site ou aplicativo, as marcas, logotipos,
                  nomes comerciais, layouts, gr&aacute;ficos e design de
                  interface, imagens, ilustra&ccedil;&otilde;es, fotografias,
                  apresenta&ccedil;&otilde;es, v&iacute;deos, conte&uacute;dos
                  escritos e de som e &aacute;udio, programas de computador,
                  banco de dados, arquivos de transmiss&atilde;o e quaisquer
                  outras informa&ccedil;&otilde;es e direitos de propriedade
                  intelectual da raz&atilde;o social BYD ENERGY DO BRASIL e BYD
                  DO BRASIL, observados os termos da Lei da Propriedade
                  Industrial (Lei n&ordm; 9.279/96), Lei de Direitos Autorais
                  (Lei n&ordm; 9.610/98) e Lei do Software (Lei n&ordm;
                  9.609/98), est&atilde;o devidamente reservados.</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >Este Termos de Uso n&atilde;o cede ou transfere ao
                  usu&aacute;rio qualquer direito, de modo que o acesso
                  n&atilde;o gera qualquer direito de propriedade intelectual ao
                  usu&aacute;rio, exceto pela licen&ccedil;a limitada ora
                  concedida.</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >O uso da plataforma pelo usu&aacute;rio &eacute; pessoal,
                  individual e intransfer&iacute;vel, sendo vedado qualquer uso
                  n&atilde;o autorizado, comercial ou n&atilde;o-comercial. Tais
                  usos consistir&atilde;o em viola&ccedil;&atilde;o dos direitos
                  de propriedade intelectual da raz&atilde;o social BYD ENERGY
                  DO BRASIL LTDA e BYD DO BRASIL, pun&iacute;veis nos termos da
                  legisla&ccedil;&atilde;o aplic&aacute;vel.</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0">12. Das san&ccedil;&otilde;es</span>
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >Sem preju&iacute;zo das demais medidas legais
                  cab&iacute;veis, a raz&atilde;o social BYD ENERGY DO BRASIL
                  LTDA e BYD DO BRASIL, poder&aacute;, a qualquer momento,
                  advertir, suspender ou cancelar a conta do
                  usu&aacute;rio:</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >a) que violar qualquer dispositivo do presente Termo;</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >b) que descumprir os seus deveres de usu&aacute;rio;</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >c) que tiver qualquer comportamento fraudulento, doloso ou
                  que ofenda a terceiros.</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5"><span class="c0">13. Da rescis&atilde;o</span></p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >A n&atilde;o observ&acirc;ncia das obriga&ccedil;&otilde;es
                  pactuadas neste Termo de Uso ou da legisla&ccedil;&atilde;o
                  aplic&aacute;vel poder&aacute;, sem pr&eacute;vio aviso,
                  ensejar a imediata rescis&atilde;o unilateral por parte da
                  raz&atilde;o social BYD ENERGY DO BRASIL LTDA e BYD DO BRASIL
                  e o bloqueio de todos os servi&ccedil;os prestados ao
                  usu&aacute;rio.</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0">14. Das altera&ccedil;&otilde;es</span>
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >Os itens descritos no presente instrumento poder&atilde;o
                  sofrer altera&ccedil;&otilde;es, unilateralmente e a qualquer
                  tempo, por parte de BYD ENERGY DO BRASIL LTDA e BYD DO BRASIL
                  para adequar ou modificar os servi&ccedil;os, bem como para
                  atender novas exig&ecirc;ncias legais. As
                  altera&ccedil;&otilde;es ser&atilde;o veiculadas pelo site, e
                  o usu&aacute;rio poder&aacute; optar por aceitar o novo
                  conte&uacute;do ou por cancelar o uso dos servi&ccedil;os,
                  caso seja assinante de algum servi&ccedil;o.</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >Os servi&ccedil;os oferecidos podem, a qualquer tempo e
                  unilateralmente, e sem qualquer aviso pr&eacute;vio, ser
                  deixados de fornecer, alterados em suas
                  caracter&iacute;sticas, bem como restringido para o uso ou
                  acesso.</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0">15. Da pol&iacute;tica de privacidade</span>
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >Al&eacute;m do presente Termo, o usu&aacute;rio dever&aacute;
                  consentir com as disposi&ccedil;&otilde;es contidas na
                  respectiva Pol&iacute;tica de Privacidade a ser apresentada a
                  todos os interessados dentro da interface da plataforma.</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5"><span class="c0">16. Do foro</span></p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >Para a solu&ccedil;&atilde;o de controv&eacute;rsias
                  decorrentes do presente instrumento ser&aacute; aplicado
                  integralmente o Direito brasileiro.</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <p class="c5">
                <span class="c0"
                  >Os eventuais lit&iacute;gios dever&atilde;o ser apresentados
                  no foro da comarca em que se encontra a sede da empresa.</span
                >
              </p>
              <p class="c3"><span class="c0"></span></p>
              <div>
                <p class="c2">
                  <span
                    style="
                      overflow: hidden;
                      display: inline-block;
                      margin: 0px 0px;
                      border: 0px solid #000000;
                      transform: rotate(0rad) translateZ(0px);
                      -webkit-transform: rotate(0rad) translateZ(0px);
                      width: 229.47px;
                      height: 6.13px;
                    "
                    ><img
                      alt=""
                      src="assets/images/terms/image1.png"
                      style="
                        width: 229.47px;
                        height: 6.13px;
                        margin-left: -0px;
                        margin-top: -0px;
                        transform: rotate(0rad) translateZ(0px);
                        -webkit-transform: rotate(0rad) translateZ(0px);
                      "
                      title=""
                  /></span>
                </p>
              </div>
            </div>
            <div class="main-button-width m-t-20 float-end d-flex">
              <button
                class="btn btn-grey"
                type="button"
                mat-dialog-close="true"
                (click)="onClose()"
              >
                {{ 'Close' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
