import { Component, inject } from '@angular/core'
import { MatDialog, MatDialogModule } from '@angular/material/dialog'
import { TranslateModule } from '@ngx-translate/core'

@Component({
  standalone: true,
  imports: [MatDialogModule, TranslateModule],
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
})
export class TermsComponent {
  private dialog = inject(MatDialog)

  onClose() {
    this.dialog.closeAll()
  }
}
